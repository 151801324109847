/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import * as backend from "bitmask-core";

import { Location } from "src/types";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import CopyButton from "src/Components/Buttons/CopyButton";
import Loader from "src/Components/Loaders/Loader";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import CheckCircle from "src/Components/Icons/CheckCircle";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";

// TODO: replace QR lib with one that can handle a 4k+ char QR code

const Success = () => {
  const [open, setOpen] = useState(false);
  const [lnTxPending, setLnTxPending] = useState(true);
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    wallet,
    vault,
    consignment,
    transaction,
    asset,
    lnInvoice,
    lnCredentials,
    hash,
  } = location.state;
  const network = window.localStorage.getItem("network");

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (lnInvoice) {
      const interval = setInterval(async () => {
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
        const paymentHash = require("light-bolt11-decoder").decode(lnInvoice)
          .sections[5].value;
        const check = await backend.lightning.checkPayment(paymentHash);
        setLnTxPending(!check);
        if (check) clearInterval(interval);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, []);

  return (
    <div className="flex flex-col justify-center w-10/12 h-full max-w-2xl m-auto">
      <div className="w-full h-auto py-6 m-auto text-center border-1 rounded-xl dark:border-darkmode-600">
        {transaction.assetType !== "Asset" &&
        transaction.assetType !== "Assets" &&
        transaction.assetType !== "token" &&
        transaction.assetType !== "UDA" ? (
          <>
            {transaction.assetType === "Lightning" ? (
              <>
                {lnTxPending && lnInvoice ? (
                  <Loader className="w-16 h-16 mx-auto" />
                ) : (
                  <CheckCircle className="w-24 h-24 m-auto mb-6" />
                )}
                <h1 className="m-auto mt-6 text-lg font-normal text-center text-black dark:text-white">
                  Invoice{" "}
                  {lnTxPending && lnInvoice ? "pending" : "paid successfully"}
                </h1>
              </>
            ) : (
              <>
                <CheckCircle className="w-24 h-24 m-auto mb-6" />
                <h1 className="m-auto text-lg font-normal text-center text-black dark:text-white">
                  Transaction submitted
                </h1>
                {transaction.id !== "" && (
                  <div
                    className="flex flex-col justify-center w-auto mx-auto my-3 text-base font-semibold text-center text-gray-900 max-w-9/12 dark:text-gray-500"
                    title="View transaction on Mempool"
                  >
                    <p className="text-sm font-thin truncate sm:text-base dark:text-gray-500">
                      {transaction.id}
                    </p>
                    <a
                      href={`${
                        network === "signet"
                          ? "https://mutinynet.com/tx/"
                          : `https://diba.mempool.space/${
                              network !== "bitcoin" ? "testnet/" : ""
                            }tx/`
                      }${transaction.id || transaction.txid}`}
                      target="_blank"
                      rel="noreferrer"
                      className="flex w-auto m-auto mt-3 text-base font-normal text-gray-800 break-all dark:text-yellow-500 xs:text-lg flex-nowrap"
                    >
                      View Transaction on Mempool{" "}
                      <ArrowUpRightIcon className="w-4 h-4 my-auto ml-1 font-bold" />
                    </a>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className="flex flex-col justify-center w-full text-gray-900 dark:text-gray-500">
            <div className="w-full h-auto m-auto bg-gray-100 pt-1.5 hidden">
              <div className="w-auto h-auto py-6 m-auto sm:py-12">
                <QRCode
                  // eslint-disable-next-line react/jsx-curly-brace-presence
                  value={`https://bitmask.app/consignment?buffer=`}
                  className="max-w-[275px] sm:max-w-[375px] sm:w-[90%] h-auto m-auto"
                  size={256}
                  viewBox="0 0 256 256"
                />
              </div>
            </div>
            <p className="mx-auto mb-6 text-lg font-light text-center text-black dark:text-white max-w-10/12">
              Send consignment to the recipient to complete the transfer.
            </p>
            <div className="flex flex-col justify-center">
              <CopyButton
                className="max-w-10/12 m-auto pt-1.5"
                title="Consignment"
                handleOnClick={() => {
                  navigator.clipboard.writeText(consignment);
                  setOpen(true);
                }}
              >
                {consignment}
              </CopyButton>
            </div>
          </div>
        )}
      </div>
      <RoundedButton
        className="w-full py-1 mx-auto mt-6 text-base text-black bg-yellow-500 sm:mt-12 sm:text-lg"
        onClick={() =>
          navigate("/wallet", {
            state: { wallet, vault, lnCredentials, hash },
          })
        }
      >
        Close
      </RoundedButton>
      <CopiedToClipboard open={open} setOpen={setOpen} />
    </div>
  );
};

export default Success;
