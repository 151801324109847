/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */

import * as backend from "bitmask-core";
import { FundVaultDetails, Vault, WalletData } from "bitmask-core/bitcoin";
import {
  ContractResponse,
  IssueRequest,
  IssueResponse,
  MediaData,
  PublishPsbtRequest,
  RgbBidRequest,
  RgbBidResponse,
  RgbInvoiceRequest,
  RgbInvoiceResponse,
  RgbOfferRequest,
  RgbOfferResponse,
  RgbSwap,
  RgbTransferRequest,
  RgbTransferResponse,
} from "bitmask-core/rgb";
import { Network } from "bitmask-core/constants";
import { getFeeRate } from "src/Hooks/util";

import React from "react";

import Step from "./Step";
import ExecuteStep from "./ExecuteStep";

interface RgbState {
  steps: ExecuteStep[];

  alice: Vault | undefined;
  aliceSk: string;
  aliceUtxo: string;
  aliceKeys: string[];
  aliceOfferId: string;
  aliceOfferAmount: string;
  aliceOfferPrice: bigint;

  bob: Vault | undefined;
  bobSk: string;
  bobKeys: string[];
  bobBtcUtxo: string;
  bobRgbUDA1Utxo: string;
  bobUDAInvoice: string;

  ifaceUDA1: string;
  contract: string;
  contractId: string;
  contractUDA1: string;
  contractMedia: MediaData;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class RgbFundVaultUDASwap extends React.Component<{ value: string }, RgbState> {
  constructor(props) {
    super(props);

    this.state = {
      steps: [],

      alice: undefined,
      aliceSk: "",
      aliceUtxo: "",
      aliceKeys: [],
      aliceOfferId: "",
      aliceOfferAmount: "",
      aliceOfferPrice: BigInt(0),

      bob: undefined,
      bobSk: "",
      bobKeys: [],
      bobBtcUtxo: "",
      bobRgbUDA1Utxo: "",
      bobUDAInvoice: "",

      ifaceUDA1: "RGB21",
      contract: "",
      contractId: "",
      contractUDA1: "",
      contractMedia: {
        type: "image/svg+xml",
        uri: "https://bitcoin.org/img/icons/logotop.svg",
      },
    };
  }

  async handleClick() {
    // 0.
    await this.useRegtest();

    // 1.
    await this.createAliceBobWallet();
  }

  // 0.
  // eslint-disable-next-line class-methods-use-this
  async useRegtest() {
    await backend.switch_network(Network.regtest.toString());
    window.localStorage.setItem("network", Network.regtest.toString());
  }

  // 1.
  async createAliceBobWallet() {
    const { ifaceUDA1 } = this.state;
    const bitcoin = "bitcoin";
    const server = `${process.env.BITMASK_ENDPOINT}`;

    const usecase = new ExecuteStep("create alice and bob wallets");

    const hash = backend.bitcoin.hashPassword("");

    const aliceWallet = await backend.bitcoin.newWallet(hash, "");
    const alice = await backend.bitcoin.decryptWallet(hash, aliceWallet);
    usecase.ok("Alice wallet created");

    const bobWallet = await backend.bitcoin.newWallet(hash, "");
    const bob = await backend.bitcoin.decryptWallet(hash, bobWallet);
    usecase.ok("Bob wallet created");

    if (alice && bob) {
      const bobSk = bob.private.nostrPrv;
      const bobKeys = [
        bob.private.rgbDescriptorXprv,
        bob.private.btcChangeDescriptorXprv,
      ];
      const aliceSk = alice.private.nostrPrv;
      const aliceKeys = [
        alice.private.rgbDescriptorXprv,
        alice.private.btcChangeDescriptorXprv,
      ];

      await backend.rgb.createWatcher(aliceSk, {
        name: "default",
        xpub: alice.public.btcDescriptorXpub,
        force: false,
      });
      usecase.ok(`Alice watcher created`);

      await backend.rgb.createWatcher(bobSk, {
        name: "default",
        xpub: bob.public.btcDescriptorXpub,
        force: false,
      });
      usecase.ok(`Bob watcher created`);

      const aliceAddr = (await backend.rgb.nextAddress(aliceSk, bitcoin))
        .address;
      usecase.ok(`Alice address (BTC): ${aliceAddr}`);

      if (aliceAddr) await fetch(`${server}/regtest/send/${aliceAddr}/1`);

      const rgbAddress = await backend.bitcoin.getNewAddress(
        alice.public.rgbDescriptorXpub
      );

      usecase.ok(`Alice address [BDK] (RGB): ${rgbAddress}`);

      // Sync Alice Wallet (BDK) - Private Keys
      usecase.ok(`Alice Sync RGB Wallet (BDK)`);
      await backend.bitcoin.getWalletData(
        alice.private.btcDescriptorXprv,
        alice.private.btcChangeDescriptorXprv
      );

      const feeRate = await getFeeRate();
      const aliceFundVault: FundVaultDetails = await backend.bitcoin.fundVault(
        alice.private.btcDescriptorXprv,
        alice.private.btcChangeDescriptorXprv,
        rgbAddress,
        true,
        feeRate
      );

      const aliceUtxo = aliceFundVault.rgbOutput || "";
      usecase.ok(`Alice UTXO [BDK] (RGB): ${aliceUtxo}`);

      const bobAddr = (await backend.rgb.nextAddress(bobSk, bitcoin)).address;
      usecase.ok(`Bob address (BTC): ${bobAddr}`);

      if (bobAddr) await fetch(`${server}/regtest/send/${bobAddr}/1`);

      const bobRgbAddr = await backend.bitcoin.getNewAddress(
        bob.public.rgbDescriptorXpub
      );
      usecase.ok(`Bob address [BDK] (RGB): ${bobRgbAddr}`);

      // Sync Bob Wallet (BDK) - Private Keys
      usecase.ok(`Bob Sync RGB Wallet (BDK)`);
      await backend.bitcoin.getWalletData(
        bob.private.btcDescriptorXprv,
        bob.private.btcChangeDescriptorXprv
      );

      const bobFundVault: FundVaultDetails = await backend.bitcoin.fundVault(
        bob.private.btcDescriptorXprv,
        bob.private.btcChangeDescriptorXprv,
        bobRgbAddr,
        true,
        feeRate
      );

      const bobRgbUDA1Utxo = bobFundVault.rgbOutput || "";
      usecase.ok(`Bob UTXO [BDK] (RGB): ${bobRgbUDA1Utxo}`);

      const bobBtcUtxo =
        (await backend.rgb.nextUtxo(bobSk, bitcoin))?.utxo || "";

      await fetch(`${server}/regtest/block`);
      this.setState(
        {
          alice,
          aliceSk,
          aliceUtxo,
          aliceKeys,
          bob,
          bobSk,
          bobKeys,
          bobBtcUtxo,
          bobRgbUDA1Utxo,
          steps: [usecase],
        },
        async () => {
          // 2.
          await this.createAliceContract();
        }
      );
    }
  }

  // 2.
  async createAliceContract() {
    const {
      aliceSk,
      aliceUtxo,
      ifaceUDA1: iface,
      contractMedia,
      steps,
    } = this.state;

    const usecase = new ExecuteStep("create alice UDA contract");

    const issueReq: IssueRequest = {
      iface,
      ticker: "DIBA",
      name: "DIBA",
      description: "DIBA",
      supply: "1",
      precision: 0,
      seal: `tapret1st:${aliceUtxo}`,
      chain: "bitcoin",
      meta: {
        preview: contractMedia,
        media: contractMedia,
        attachments: [contractMedia],
      },
    };

    const {
      iface: contractIface,
      contractId,
      contract: { armored: contract },
      balance,
    }: IssueResponse = JSON.parse(
      await backend.issue_contract_proxy(aliceSk, issueReq)
    ).data;

    usecase.ok(
      `Alice contract: [${contractIface}] ${contractId} (${balance.value})`
    );

    steps.push(usecase);
    this.setState(
      { contractId, contract, contractUDA1: contract, steps },
      async () => {
        // 3.
        await this.createAliceOffer();
      }
    );
  }

  // 3.
  async createAliceOffer() {
    const { aliceSk, aliceKeys, contractId, ifaceUDA1, steps } = this.state;
    const usecase = new ExecuteStep("create alice offer");
    console.log("aja");
    const assetAmount = "1";
    const bitcoinPrice = BigInt(1000);
    const offerReq: RgbOfferRequest = {
      strategy: RgbSwap.p2p,
      offers: [{ contractId, iface: ifaceUDA1, assetAmount, bitcoinPrice }],
    };
    console.log("offerReq", offerReq);
    const offer = JSON.parse(
      await backend.create_p2p_offer(aliceSk, offerReq, aliceKeys)
    ).data;
    console.log("offer", offer);
    const {
      offerId: aliceOfferId,
      bitcoinPrice: aliceOfferPrice,
    }: RgbOfferResponse = offer[0];
    usecase.ok(`Alice offer created: ${aliceOfferId} (${aliceOfferPrice})`);

    steps.push(usecase);
    this.setState(
      {
        aliceOfferId,
        aliceOfferPrice,
        aliceOfferAmount: assetAmount,
        steps,
      },
      async () => {
        // 4.
        await this.createBobBid();
      }
    );
  }

  // 4.
  async createBobBid() {
    const {
      bobSk,
      bobKeys,
      aliceOfferId,
      aliceOfferAmount,
      aliceOfferPrice,
      contractId,
      contract,
      steps,
    } = this.state;
    const usecase = new ExecuteStep("create bob bid");

    await backend.import_contract(bobSk, contract);
    usecase.ok(`Bob imported contract: ${contractId}`);

    const chainFee = { value: BigInt(1000) };
    const bidReq: RgbBidRequest = {
      offerId: aliceOfferId,
      assetAmount: aliceOfferAmount,
      bitcoinPrice: aliceOfferPrice,
      fee: chainFee,
    };

    const { bidId, swapPsbt }: RgbBidResponse = JSON.parse(
      await backend.create_p2p_bid(bobSk, bidReq, bobKeys)
    ).data;

    usecase.ok(`Bob bid created: ${bidId} (${aliceOfferPrice})`);

    const psbt = swapPsbt?.toString() || "";
    const publishReq: PublishPsbtRequest = { psbt };
    backend.psbt_publish_file(publishReq);

    usecase.ok(`Bob published PSBT`);

    steps.push(usecase);
    this.setState({ steps }, async () => {
      // 5.
      await this.updateAliceUtxos();
    });
  }

  // 5.
  async updateAliceUtxos() {
    const { aliceSk, alice, ifaceUDA1: iface, steps } = this.state;
    const usecase = new ExecuteStep("update alice utxos");

    await backend.verify_transfers(aliceSk);
    usecase.ok(`Alice update transfers`);

    const aliceUtxo = (await backend.rgb.nextUtxo(aliceSk, iface))?.utxo || "";
    usecase.ok(`Alice UTXO [WATCHER] (${iface}): ${aliceUtxo}`);

    const alicePk = alice?.private.btcDescriptorXprv || "";
    const aliceVault = await backend.bitcoin.getWalletData(alicePk);
    usecase.ok(`Alice UTXO [BDK] (${iface}): ${aliceVault.utxos[0]}`);

    steps.push(usecase);
    this.setState({ steps, aliceUtxo }, async () => {
      // 6.
      await this.aliceContracts();
    });
  }

  // 6.
  async aliceContracts() {
    const { aliceSk, contractId, steps } = this.state;
    const usecase = new ExecuteStep("alice contracts state verification");

    await backend.verify_transfers(aliceSk);
    usecase.ok(`Alice update transfers`);

    const {
      iface: iface21,
      balance: balance21,
      allocations: allocations21,
    }: ContractResponse = JSON.parse(
      await backend.get_contract(aliceSk, contractId)
    );

    usecase.ok(
      `Alice contract: [${iface21}]  ${contractId} / value: ${JSON.stringify(
        balance21.value
      )}`
    );

    const filterAllocations = allocations21.filter(
      (x) => x.isMine && !x.isSpent
    );
    usecase.ok(`Alice allocations: (${JSON.stringify(filterAllocations)})`);

    steps.push(usecase);
    this.setState({ steps }, async () => {
      // 9.
      await this.bobContracts();
    });
  }

  // 9.
  async bobContracts() {
    const { bobSk, contractId, steps } = this.state;
    const usecase = new ExecuteStep("bob contracts state verification");

    await backend.verify_transfers(bobSk);
    usecase.ok(`Bob update transfers`);

    const {
      iface: iface21,
      balance: balance21,
      allocations: allocations21,
    }: ContractResponse = JSON.parse(
      await backend.get_contract(bobSk, contractId)
    );

    usecase.ok(
      `Bob contract: [${iface21}]  ${contractId} / value: ${JSON.stringify(
        balance21.value
      )}`
    );

    const filterAllocations = allocations21.filter(
      (x) => x.isMine && !x.isSpent
    );
    usecase.ok(`Bob allocations: (${JSON.stringify(filterAllocations)})`);

    steps.push(usecase);
    this.setState({ steps });
  }

  render() {
    const { steps } = this.state;
    const { value } = this.props;
    return (
      <>
        <div className="shadow-lg cursor-pointer dark:bg-newdarkmode-800 dark:border-1/2 dark:border-newdarkmode-600 dark:border-opacity-25 rounded-xl divide-y-1/2 divide-newdarkmode-600">
          <div
            className="flex items-center my-auto sm:justify-between sm:w-full"
            onClick={async () => this.handleClick()}
            onKeyDown={async () => this.handleClick()}
            role="presentation"
          >
            <div className="relative flex py-2 pl-6 cursor-pointer xs:py-4 md:pl-9 lg:px-6 focus:outline-none">
              <p className="pr-4 my-auto text-base text-left text-yellow-500 xl:text-lg">
                {value}
              </p>
            </div>
          </div>
        </div>
        {steps.map((step: ExecuteStep) => (
          <Step
            key={step.usecase}
            usecase={step.usecase}
            result={step.result}
          />
        ))}
      </>
    );
  }
}

export default RgbFundVaultUDASwap;
