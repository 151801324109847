/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* global chrome */
import React, { useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { generateUsername } from "unique-username-generator";
import * as backend from "bitmask-core";
import { CARBONADO, LNDHUBX, init } from "bitmask-core/constants";

import { TEST_IDS } from "tests/ids";

import TextField from "src/Components/Inputs/TextField";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import useWindowSize from "src/Hooks/useWindowSize";
import lib, { isExtension } from "src/lib";
import { Preload } from "src/types";
import ErrorModal from "src/Components/Modals/Error";
import PinPad from "src/Components/Inputs/PinPad";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { classNames, setHash } from "src/Hooks/util";

interface ImportWalletArgs {
  hash: string;
  words: string;
  navigate: NavigateFunction;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setError?: React.Dispatch<
    React.SetStateAction<{ title: string; message: string }>
  >;
  reload?: boolean;
}

export const importWallet = async ({
  hash,
  words,
  navigate,
  setLoading,
  setOpen,
  setError,
  reload,
}: ImportWalletArgs) => {
  console.warn("reimporting wallet");

  if (setLoading) setLoading(true);
  const wallet = "wallet_01";
  // TODO: Second argument is the extension encryption password, third argument is seed password
  try {
    const createWatcher = async (
      nostrKey: string,
      xpub: string,
      network: string
    ): Promise<void> => {
      if (CARBONADO) {
        await backend.rgb.createWatcher(nostrKey, {
          name: "default",
          xpub,
          force: false,
        });

        console.debug(`watcher created for ${network} network`);
      } else {
        console.debug(`cannot be create watcher for ${network} network`);
      }
    };

    const pin = sessionStorage.getItem("pin") || "";

    const createTestnetDescriptor = async () => {
      const networkDesc = backend.constants.Network.testnet;
      await backend.constants.switchNetwork(networkDesc);
      const encryptedDescriptors = await backend.bitcoin.encryptWallet(
        words.trim(),
        hash,
        pin
      );
      window.localStorage.setItem("testnetDescriptor", encryptedDescriptors);
      const vault = await backend.bitcoin.decryptWallet(
        hash,
        encryptedDescriptors
      );
      await createWatcher(
        vault.private.nostrPrv,
        vault.public.rgbDescriptorXpub,
        networkDesc
      );
    };
    const createSignetDescriptor = async () => {
      const networkDesc = backend.constants.Network.signet;
      await backend.constants.switchNetwork(networkDesc);
      const encryptedDescriptors = await backend.bitcoin.encryptWallet(
        words.trim(),
        hash,
        pin
      );
      window.localStorage.setItem("signetDescriptor", encryptedDescriptors);
      const vault = await backend.bitcoin.decryptWallet(
        hash,
        encryptedDescriptors
      );
      await createWatcher(
        vault.private.nostrPrv,
        vault.public.rgbDescriptorXpub,
        networkDesc
      );
    };
    const createRegtestDescriptor = async () => {
      const networkDesc = backend.constants.Network.regtest;
      await backend.constants.switchNetwork(networkDesc);
      const encryptedDescriptors = await backend.bitcoin.encryptWallet(
        words.trim(),
        hash,
        pin
      );
      window.localStorage.setItem("regtestDescriptor", encryptedDescriptors);
      const vault = await backend.bitcoin.decryptWallet(
        hash,
        encryptedDescriptors
      );
      await createWatcher(
        vault.private.nostrPrv,
        vault.public.rgbDescriptorXpub,
        networkDesc
      );
    };
    const createBitcoinDescriptor = async () => {
      const networkDesc = backend.constants.Network.bitcoin;
      await backend.constants.switchNetwork(networkDesc);
      const encryptedDescriptors = await backend.bitcoin.encryptWallet(
        words.trim(),
        hash,
        pin
      );
      window.localStorage.setItem("bitcoinDescriptor", encryptedDescriptors);
      const vault = await backend.bitcoin.decryptWallet(
        hash,
        encryptedDescriptors
      );
      await setHash(hash, vault.public.xpubkh);
      await createWatcher(
        vault.private.nostrPrv,
        vault.public.rgbDescriptorXpub,
        networkDesc
      );
      const authLightningWallet = async (lndhubEndpoint: string) => {
        await init();
        let refresh = "";
        let token = "";
        const tokens = await backend.lightning.auth(
          vault.public.xpubkh,
          vault.private.xprvkh
        );
        const reloadScriptAndTabs = () => {
          if (isExtension) {
            chrome.runtime.sendMessage({
              call: "reload_script_and_tabs",
            });
          } else if (reload) {
            window.location.reload();
          }
        };
        const handleWalletCreation = async (rf, tkn) => {
          await lib.storageSet({ pubKeyHash: vault.public.xpubkh });
          reloadScriptAndTabs();
          navigate("/wallet", {
            replace: true,
            state: {
              wallet,
              vault,
              hash,
              lnCredentials: {
                login: vault.public.xpubkh,
                password: vault.private.xprvkh,
                refreshToken: rf,
                accessToken: tkn,
              },
            },
          });
        };
        if ("error" in tokens) {
          if (tokens.error === "UserDoesNotExist") {
            await backend.lightning.createWallet(
              vault.public.xpubkh,
              vault.private.xprvkh
            );
            const retryTokens = await backend.lightning.auth(
              vault.public.xpubkh,
              vault.private.xprvkh
            );
            if ("error" in retryTokens) {
              if (setError)
                setError({
                  title: "Error Ocurred on Wallet Import",
                  message: retryTokens.error,
                });
              if (setOpen) setOpen(true);
            } else {
              refresh = retryTokens.refresh;
              token = retryTokens.token;
              handleWalletCreation(refresh, token);
            }
          } else {
            if (setError)
              setError({
                title: "Error Ocurred on Wallet Import",
                message: tokens.error,
              });
            if (setOpen) setOpen(true);
          }
        } else {
          refresh = tokens.refresh;
          token = tokens.token;
          handleWalletCreation(refresh, token);
        }
      };
      const handleCreateWatcherAndLnAuth = async () => {
        if (LNDHUBX) {
          /* if (process.env.TEST_LNDHUB_ENDPOINT)
            await authLightningWallet(process.env.TEST_LNDHUB_ENDPOINTEST_LNDHUB_ENDPOINTT); */
          if (process.env.PROD_LNDHUB_ENDPOINT)
            await authLightningWallet(process.env.PROD_LNDHUB_ENDPOINT);
        }
        if (CARBONADO) {
          await createWatcher(
            vault.private.nostrPrv,
            vault.public.rgbDescriptorXpub,
            networkDesc
          );
        }
      };
      const checkUsername = await fetch(
        `${
          isExtension ? "https://beta.bitmask.app" : ""
        }/api/checkusername?lookup=${vault.public.xpubkh}`
      );
      const checkedUsername = await checkUsername.json();

      if (checkedUsername.error) {
        if (setLoading) setLoading(false);
        if (setError)
          setError({
            title: "Unhandled exception in wallet import",
            message: checkedUsername.error,
          });
        if (setOpen) setOpen(true);
      } else if (checkedUsername.success && checkedUsername.username) {
        window.localStorage.setItem(
          "username",
          `${checkedUsername.username.toLowerCase()}@bitmask.app`
        );
        await handleCreateWatcherAndLnAuth();
      } else if (checkedUsername.notFound) {
        const username = generateUsername("-", 9);
        const createUsername = await fetch(
          `${isExtension ? "https://beta.bitmask.app" : ""}/api/username`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: username.toLowerCase(),
              xpubkh: vault.public.xpubkh,
              xprvkh: vault.private.xprvkh,
            }),
          }
        );
        const creationSuccess = await createUsername.json();

        if (creationSuccess.success === true) {
          window.localStorage.setItem(
            "username",
            `${username.toLowerCase()}@bitmask.app`
          );
          await handleCreateWatcherAndLnAuth();
        } else if (creationSuccess.reason) {
          if (setError) setError(creationSuccess.reason);
        } else {
          if (setLoading) setLoading(false);
          if (setError)
            setError({
              title: "Unhandled exception in username creation",
              message: "Unhandled exception in username creation.",
            });
          if (setOpen) setOpen(true);
        }
      }
    };
    await init();
    await createTestnetDescriptor();
    await createSignetDescriptor();
    await createRegtestDescriptor();
    await createBitcoinDescriptor();

    localStorage.removeItem("isRecoveryState");

    navigate("/wallet", {
      state: {
        wallet,
        hash,
      },
    });
  } catch (resError) {
    if (setLoading) setLoading(false);
    if (setError)
      setError({
        title:
          (resError as Error)?.name || "Unhandled exception in importWallet",
        message:
          (resError as Error)?.message ||
          resError?.toString() ||
          "Unhandled exception",
      });
    if (setOpen) setOpen(true);

    console.error("error in WalletImport", resError);
  }
};

const WalletImport = () => {
  const size = useWindowSize();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pin, setPin] = useState("");
  const [pinEntered, setPinEntered] = useState(false);
  const [words, setWords] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [seedError, setSeedError] = useState(false);
  const urlParams = useParams();
  const network = window.localStorage.getItem("network");

  const handleSubmit = async () => {
    const hash = backend.bitcoin.hashPassword(password);
    window.localStorage.setItem("network", backend.constants.Network.bitcoin);
    await importWallet({
      hash,
      words,
      navigate,
      setLoading,
      setOpen,
      setError,
    });
  };

  const openWallet = async (seed: string, hash: string) => {
    setLoading(true);
    const wallet = "wallet_01";
    // TODO: Second argument is the extension encryption password, third argument is seed password
    try {
      const encryptedDescriptors = await backend.bitcoin.encryptWallet(
        seed.trim(),
        hash,
        pin.trim()
      );

      sessionStorage.setItem("pin", pin.trim());
      window.localStorage.setItem(`${network}Descriptor`, encryptedDescriptors);

      const vault = await backend.bitcoin.decryptWallet(
        hash,
        encryptedDescriptors
      );
      await setHash(hash, vault.public.xpubkh);
      const walletData = await backend.bitcoin.getWalletData(
        vault.private.btcDescriptorXprv,
        vault.private.btcChangeDescriptorXprv
      );

      const createWatcher = async (
        nostrKey: string,
        xpub: string
      ): Promise<void> => {
        if (CARBONADO) {
          await backend.rgb.createWatcher(nostrKey, {
            name: "default",
            xpub,
            force: false,
          });
        }
      };

      const handleOpenWallet = async () => {
        const reloadScriptAndTabs = () => {
          if (isExtension) {
            chrome.runtime.sendMessage({
              call: "reload_script_and_tabs",
            });
          }
        };
        console.debug(`vault: ${vault}`);

        const handleWalletCreation = async (rf, tkn) => {
          reloadScriptAndTabs();
          navigate("/wallet", {
            state: {
              wallet,
              vault,
              hash,
              mainnetVault: vault.private.xprvkh,
              lnCredentials: {
                login: vault.public.xpubkh,
                password: vault.private.xprvkh,
                refreshToken: rf,
                accessToken: tkn,
              },
            },
          });
        };

        let refresh = "";
        let token = "";
        await createWatcher(
          vault.private.nostrPrv,
          vault.public.rgbDescriptorXpub
        );
        if (LNDHUBX && network === "bitcoin") {
          const tokens = await backend.lightning.auth(
            vault.public.xpubkh,
            vault.private.xprvkh
          );

          if ("error" in tokens) {
            if (tokens.error === "UserDoesNotExist") {
              const lnWallet = await backend.lightning.createWallet(
                vault.public.xpubkh,
                vault.private.xprvkh
              );
              const retryTokens = await backend.lightning.auth(
                vault.public.xpubkh,
                vault.private.xprvkh
              );
              if ("error" in retryTokens) {
                setError({
                  title: "Error Ocurred on Wallet Import",
                  message: retryTokens.error,
                });
                setOpen(true);
              } else {
                refresh = retryTokens.refresh;
                token = retryTokens.token;
                handleWalletCreation(refresh, token);
              }
            } else {
              setError({
                title: "Error Ocurred on Wallet Import",
                message: tokens.error,
              });
              setOpen(true);
            }
          } else {
            refresh = tokens.refresh;
            token = tokens.token;
            handleWalletCreation(refresh, token);
          }
        }
        handleWalletCreation(refresh, token);
      };
      if (walletData.balance.confirmed > 0) {
        await handleOpenWallet();
      } else if (emptyError) {
        setLoading(false);
        await handleOpenWallet();
      } else {
        setLoading(false);
        setEmptyError(true);
      }
    } catch (err) {
      console.error("error in WalletImport", err);
    }
  };

  const handleSeedSubmit = async () => {
    if (urlParams.preload) {
      const { seed, context, hash }: Preload = JSON.parse(
        atob(urlParams.preload)
      );
      openWallet(seed, hash);
    }
  };

  return (
    <>
      {urlParams.preload ? (
        <div className="w-11/12 h-auto max-w-2xl pb-10 m-auto mx-3 text-center md:w-full xs:mx-6 sm:m-auto">
          <div className="flex flex-col justify-between w-auto h-auto m-auto text-center xs:pt-8 xs:pb-10 sm:mx-auto">
            {!emptyError ? (
              <div className="h-auto text-center">
                <h1 className="mt-3 text-xl font-semibold text-center text-black xs:text-2xl dark:text-white">
                  Add a seed password to the wallet
                </h1>
                <div className="flex justify-center w-full h-auto mt-3 mb-6">
                  <PinPad
                    pin={pin}
                    setPin={setPin}
                    onPinEntered={(val) => setPinEntered(val)}
                  />
                </div>
                <RoundedButton
                  className="py-3 mx-auto mt-4 text-base font-semibold text-gray-900 bg-yellow-500 sm:text-lg lg:text-xl disabled:pointer-events-none disabled:bg-opacity-25 disabled:select-none"
                  onClick={() => handleSeedSubmit()}
                  disabled={!pinEntered}
                  loading={loading}
                >
                  Import Wallet
                </RoundedButton>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto mb-3 bg-red-100 rounded-full dark:bg-newdarkmode-700">
                  <ExclamationTriangleIcon
                    className="w-8 h-8 text-red-600 dark:text-yellow-500"
                    aria-hidden="true"
                  />
                </div>
                <h1 className="text-xl font-semibold text-center text-black xs:text-2xl dark:text-white">
                  Error Importing Paper Wallet
                </h1>
                <p className="w-11/12 mx-auto mt-6 text-base font-light text-black dark:text-gray-300 sm:w-10/12">
                  This wallet appears empty, did you enter the correct PIN?
                </p>
                <div className="w-11/12 mx-auto sm:w-9/12 pt-9 grid grid-cols-2">
                  <RoundedButton
                    className="mr-2 text-base text-black border-2 border-black-500 dark:border-yellow-500 dark:text-yellow-400"
                    onClick={() => {
                      setEmptyError(false);
                      setPin("");
                    }}
                  >
                    Cancel
                  </RoundedButton>
                  <RoundedButton
                    className="ml-2 text-base bg-yellow-500 border-2 border-yellow-500 text-newdarkmode-900"
                    onClick={() => {
                      if (urlParams.preload) {
                        const { seed, context, hash }: Preload = JSON.parse(
                          atob(urlParams.preload)
                        );
                        openWallet(seed, hash);
                      }
                    }}
                    loading={loading}
                  >
                    Create Anyway
                  </RoundedButton>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="w-11/12 h-auto max-w-2xl pb-10 m-auto mx-3 text-center md:w-full xs:mx-6 sm:m-auto">
          <div className="flex flex-col justify-between w-auto h-auto m-auto text-center xs:pt-8 xs:pb-10 sm:mx-auto">
            <div className="h-auto px-6 text-center">
              <h1 className="text-2xl font-medium text-center text-gray-900 xs:text-3xl dark:text-gray-300 flex-grow-default">
                Import Wallet
              </h1>
              <TextField
                className="w-full p-3 my-2"
                placeholder="Create New Password"
                label="Password"
                type="password"
                autoComplete="new-password"
                onChange={(e) => setPassword(e.target.value)}
                darkest={size.width >= 500}
                data-test-id={TEST_IDS.walletimport.create}
              />
              {password.length < 8 && password !== "" && (
                <div className="w-full m-auto">
                  <p className="text-sm text-left text-red-600 flex-grow-default">
                    Must be 8 characters
                  </p>
                </div>
              )}
              <TextField
                className="w-full p-3 my-2"
                placeholder="Confirm Password"
                label="Confirm password"
                type="password"
                autoComplete="new-password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                darkest={size.width >= 500}
                data-test-id={TEST_IDS.walletimport.confirm}
              />
              {password !== confirmPassword && confirmPassword !== "" && (
                <div className="w-full m-auto">
                  <p className="text-sm text-left text-red-600 flex-grow-default">
                    Passwords must match
                  </p>
                </div>
              )}
              <TextField
                className="w-full p-3 my-2"
                placeholder="Seed phrase"
                label="Enter Seed Phrase"
                type="text"
                value={words}
                onChange={(e) => setWords(e.target.value.toLowerCase())}
                onKeyDown={(e) => (e.key === "Enter" ? handleSubmit() : null)}
                darkest={size.width >= 500}
                data-test-id={TEST_IDS.walletimport.mnemonic}
              />
              {!urlParams.seed && (
                <p className="mt-3 mb-6 text-sm font-thin text-left text-gray-500 flex-grow-default sm:text-base">
                  Enter seed phrase in order from start to finish, with a single
                  space after each word.
                </p>
              )}
              {seedError && (
                <span className="text-base text-red-600">
                  Incorrect Seed Phrase. Please try again.
                </span>
              )}
              <RoundedButton
                className={classNames(
                  password !== confirmPassword || password.length < 8 || !words
                    ? "opacity-50"
                    : "",
                  "w-full py-1 mx-auto mt-8 text-base font-medium text-gray-900 bg-yellow-500 sm:text-lg"
                )}
                onClick={() => handleSubmit()}
                loading={loading}
                disabled={
                  password !== confirmPassword || password.length < 8 || !words
                }
              >
                Import Wallet
              </RoundedButton>
            </div>
          </div>
        </div>
      )}
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </>
  );
};

export default WalletImport;
